import React, { useState, useMemo } from "react";
import { BsArrowDownUp } from "react-icons/bs";

const CollectionFetchTable = ({ currentPosts, handleCheckboxChange, selectedIds, selectAll, handleSelectAll }) => {
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevSortDirection) =>
        prevSortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedData = useMemo(() => {
    const sortedArray = [...currentPosts];

    if (sortColumn !== "") {
      sortedArray.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (sortColumn === "lastmodified") {
          // Convert timestamps to Date objects for comparison
          const dateA = new Date(valueA).getTime();
          const dateB = new Date(valueB).getTime();

          if (dateA < dateB) return sortDirection === "asc" ? -1 : 1;
          if (dateA > dateB) return sortDirection === "asc" ? 1 : -1;
          return 0;
        } else {
          if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
          if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
          return 0;
        }
      });
    }

    return sortedArray;
  }, [currentPosts, sortColumn, sortDirection]);

  function formatDateString(originalDateString) {
    const dateParts = originalDateString.split(/[- :]/);

    // Extract year, month, day, hour, minute
    //const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    const hour = dateParts[4];
    const minute = dateParts[5];

    // Create the formatted string
    const formattedString = `${month}-${day} ${hour}:${minute}`;

    return formattedString;
  }

  return (
    <>
      <div className="container-fluid mb-2 mt-2">
        <table id="" className="table table-bordered" style={{ color: "#222" }}>
          <thead className="">
            <tr>
              <th className="width-2p text-center" scope="row">
                <input
                  type="checkbox"
                  checked={
                    currentPosts.length > 0 &&
                    currentPosts.every(item => selectedIds.includes(item.id))
                  }
                  onChange={handleSelectAll}
                />
              </th>
              <th onClick={() => handleSort("id")} className="width-4p text-center" scope="col">
                S.No.{" "}
                <b>
                  <BsArrowDownUp />
                </b>
              </th>
              <th onClick={() => handleSort("id")} className="width-3p text-center" scope="col">
                id{" "}
                <b>
                  <BsArrowDownUp />
                </b>
              </th>
              <th className="width-12p" scope="col">
                Source Text
              </th>
              <th className="width-12p" scope="col">
                Clean Text
              </th>
              <th className="width-7p" scope="col">
                source MD5
              </th>
              <th className="width-7p" scope="col">
                Clean MD5
              </th>
              <th className="width-7p" scope="col">
                Script Path
              </th>
              <th className="width-2p" scope="col">
                Size
              </th>

              <th
                onClick={() => handleSort("lastmodified")}
                className="width-6p text-center"
                scope="col"
              >
                Request Date{" "}
                <b>
                  <BsArrowDownUp />
                </b>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((myData, index) => (
              <tr key={myData.id} style={{ backgroundColor: selectedIds.includes(myData.id) ? '#c1d3f0' : '' }}>
                <td className="">
                  <div className="custom-control custom-checkbox text-center"
                    style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`customCheck${myData.id}`}
                      checked={selectedIds.includes(myData.id) || selectAll}
                      onChange={() => handleCheckboxChange(myData.id)}
                    />
                  </div>
                </td>
                <td className="text-center width-4p" style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>{index + 1}</td>
                <td className="text-center" style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>{myData.id}</td>
                <td style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>
                  <div> {myData.srctxt} </div>
                </td>
                <td style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>
                  <div> {myData.cleantxt} </div>
                </td>
                <td style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>
                  {myData.srcmd5}
                </td>
                <td style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>
                  {myData.cleanmd5}
                </td>
                <td style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>
                  <div> {myData.scriptpath.slice(33,)} </div>
                </td>
                <td style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>
                  <div> {myData.len} </div>
                </td>
                <td style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>
                  <div className="text-center">{formatDateString(myData.reqdate)}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CollectionFetchTable;
