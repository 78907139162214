import React, { useState, useEffect } from "react";

const DomainDropdown = ({
  data,
  selectedDomain,
  setSelectedDomain,
  setFilterData,
}) => {
  const [selectedURL, setSelectedURL] = useState("");
  const [domainURLs, setDomainURLs] = useState([]);

  useEffect(() => {
    // Calculate the unique URLs for the selected domain
    const uniqueURLs = Array.from(
      new Set(
        data
          .filter((state) => state.domain === selectedDomain)
          .map((state) => state.scriptpath)
          .filter((url) => url && url.trim() !== "")
      )
    ).sort();

    setDomainURLs(uniqueURLs);
  }, [data, selectedDomain]);

  const handleDomainChange = (event) => {
    const selectedDomain = event.target.value;
    setSelectedDomain(selectedDomain);
    setSelectedURL(""); // Reset the selected URL when the domain changes
  };

  const handleURLChange = (event) => {
    const selectedURL = event.target.value;
    setSelectedURL(selectedURL);
  };

  const handleFilter = () => {
    filterData(selectedDomain, selectedURL);
  };

  const filterData = (selectedDomain, selectedURL) => {
    const result = data.filter((state) => {
      const domainName = state.domain || "";
      const url = state.scriptpath || "";

      const domainMatch =
        selectedDomain === "" || domainName.toLowerCase() === selectedDomain.toLowerCase();
      const urlMatch = selectedURL === "" || url.toLowerCase() === selectedURL.toLowerCase();

      return domainMatch && urlMatch;
    });

    setFilterData(result);
  };


  return (
    <>
      <div className="d-flex">
        <div className="mt-0 col-md-4">
          <select className="form-control" value={selectedDomain} onChange={handleDomainChange}>
            <option value="">Selected Domain</option>
            {Array.from(new Set(data.map((state) => state.domain)))
              .filter((domain) => domain && domain.trim() !== "")
              .sort()
              .map((domain, index) => (
                <option key={index} value={domain}>
                  {domain}
                </option>
              ))}
          </select>
        </div>
        <div className="col-md-6 ms-1">
          <select className="form-control mt-0" value={selectedURL} onChange={handleURLChange}>
            <option value="">Select URL</option>
            {domainURLs.map((url, index) => (
              <option key={index} value={url}>
                {url}
              </option>
            ))}
          </select>
        </div>
        <button className="btn btn-light border ms-1" onClick={handleFilter}>GO</button>
      </div>
    </>
  );
};

export default DomainDropdown;
